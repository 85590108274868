import * as React from 'react'
import PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import slugify from "@sindresorhus/slugify"

import Layout from '../../components/layout/Layout'
import ProduitCard from '../../components/card/Produit'

// Produits
const ProduitsPage = ({ pageContext, data }) => {
  const { totalCount } = data.produits
  const pageTitle = `Produits - ${totalCount} produit${
    totalCount === 1 ? "" : "s"
  }`
  const header = `${totalCount} Produit${
    totalCount === 1 ? "" : "s"
  }`

  return (
    <Layout pageTitle={pageTitle} headerClassPosition="sticky-top">
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>{header}</h2>
            <ol>
              <li key="accueil">
                <Link to={`/`} className="text-reset">Accueil</Link>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-3 col-lg-2">
              <div className="row">
                <div className="col-4 col-md-12">
                  <div className="h5">Marques</div>
                  <ul className="list-unstyled fw-normal pb-1 small">
                    {data.marques.group.map(marque => (
                      <li key={marque.nom}>
                        <Link to={`/produits/marques/${slugify(marque.nom)}/`} className="text-reset">
                          {marque.nom} <span className="badge rounded-pill bg-light text-reset">{marque.totalCount}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-4 col-md-12">
                  <div className="h5">Catégories</div>
                  <ul className="list-unstyled fw-normal pb-1 small">
                    {data.categories.group.map(categorie => (
                      <li key={categorie.nom}>
                        <Link to={`/produits/categories/${slugify(categorie.nom)}/`} className="text-reset">
                          {categorie.nom} <span className="badge rounded-pill bg-light text-reset">{categorie.totalCount}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-4 col-md-12">
                  <div className="h5">Couleurs</div>
                  <ul className="list-unstyled fw-normal pb-1 small">
                    {data.couleurs.group.map(couleur => (
                      <li key={couleur.nom}>
                        <Link to={`/produits/couleurs/${slugify(couleur.nom)}/`} className="text-reset">
                          {couleur.nom} <span className="badge rounded-pill bg-light text-reset">{couleur.totalCount}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-9 col-lg-10">
              <div className="row">
                {
                  data.produits.nodes.map(produit => (
                    <ProduitCard key={produit.id} data={produit}/>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

ProduitsPage.propTypes = {
  data: PropTypes.shape({
    marques: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          nom: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    categories: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          nom: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    couleurs: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          nom: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
  }),
}

export const query = graphql`
  query {
    produits: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/produits/"},
        frontmatter: { accueil: { eq: true }, actif: { eq: true } }
      },
      sort: {fields: frontmatter___date, order: ASC})
    {
      nodes {
        frontmatter {
          titre
          marque
          categorie
          sous_categorie
          card_image {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          card_image_alt
          hero_image {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          hero_image_alt
          hero_image_credit_link
          hero_image_credit_text
          accueil
          actif
          date(formatString: "MMMM D, YYYY")
        }
        id
        slug
      }
      totalCount
    }
    marques: allMdx {
      group(field: frontmatter___marque) {
        nom: fieldValue
        totalCount
      }
    }
    categories: allMdx {
      group(field: frontmatter___categorie) {
        nom: fieldValue
        totalCount
      }
    }
    couleurs: allMdx {
      group(field: frontmatter___couleurs) {
        nom: fieldValue
        totalCount
      }
    }
  }
`

export default ProduitsPage